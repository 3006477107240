import React, { useEffect, useState, startTransition } from "react";
import { InlineMenu, InlineMenuItem, PopIconMenuItem, PopMenuItem, Tab, TabContainer, TabContents, TabHeader, Title } from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetIcon } from "../../../icons";
import { HLine } from "../dashboard/styles";
import ListTable from "../list/list";
import CrudForm from "../list/create";
import ImageGallery from "../list/imagegallery";
import { CustomPageTemplate } from "../list/custom";
import RenderSubPage from "../../project/router/pages";
import { DisplayInformations } from "../list/popup";
import { More } from "../list/styles";
import { Head } from "../list/popup/styles";

const Tabs = ({ tabs = [], className = "", popupMenu = "horizontal", editData, setMessage, setLoaderBox, openData, parents, item }) => {
  const [t] = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  const [activeTab, setActiveTab] = useState(tabs[0]?.name);
  const [subActiveTab, setSubActiveTab] = useState(null);
  const [subActiveInlineTab, setSubActiveInlineTab] = useState(null);
  const [openedTab, setOpenedTab] = useState({});
  const [subMenus, setSubMenus] = useState(null);

  useEffect(() => {
    if (activeTab === null) {
      setActiveTab(tabs[0]?.name);
    }
  }, [tabs, activeTab]);

  const renderPage = (tab, editData, setMessage, setLoaderBox, openData, parents) => {
    const { element, type, content } = tab;
    if (!element) {
      // console.log(tab);
    }
    return !type ? null : type === "custom" ? (
      <CustomPageTemplate key={tab.name} openData={openData} {...element} themeColors={themeColors} setLoaderBox={setLoaderBox} setMessage={setMessage} content={content ?? RenderSubPage(tab.element, content)}></CustomPageTemplate>
    ) : type === "information" ? (
      <CrudForm {...editData} css="plain head-hide info"></CrudForm>
    ) : type === "gallery" ? (
      <ImageGallery key={tab.name} showTitle={element.showTitle} api={`${element.api}/upload`} openData={openData} />
    ) : type === "edit" ? (
      <CrudForm {...editData} css="plain head-hide info"></CrudForm>
    ) : type === "details" ? (
      <TabContainer className="tab">
        <Head className="sticky">
          <div>{content.titleValue}</div>
          <div>
            {content.updatePrivilege && (
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  content.isEditingHandler(openData?.data, content.udpateView, content.titleValue);
                }}
              >
                <GetIcon icon={"edit"} />
              </More>
            )}
          </div>
        </Head>
        <DisplayInformations popupMenu={popupMenu} formMode={content.formMode} attributes={openData.attributes} data={openData.data} />
      </TabContainer>
    ) : (
      element?.attributes && (
        <ListTable
          name={tab.name}
          headerStyle={"sub"}
          icon={element.icon ?? ""}
          showInfo={element.showInfo ?? true}
          viewMode={element.type ?? "subList"}
          setMessage={setMessage}
          setLoaderBox={setLoaderBox}
          parentReference={element?.params?.parentReference}
          referenceId={openData?.data?._id}
          attributes={element.attributes}
          {...element.params}
          parents={{
            ...parents,
            [item?.params?.parentReference]: openData?.data?._id,
          }}
        ></ListTable>
      )
    );
  };
  return (
    <TabContainer className={popupMenu}>
      {tabs.length > 0 && (
        <TabHeader className={`sub-menu ${className} ${popupMenu}`}>
          <HLine className={popupMenu}></HLine>
          {tabs.map((tab, index) => {
            return (
              <PopIconMenuItem
                key={`${tab.name}-${index}`} // Updated key
                theme={themeColors}
                className={`${tab.name} ${activeTab === tab.name && "active"}  ${popupMenu}`}
                onClick={() => {
                  startTransition(() => {
                    setActiveTab(tab.name);
                    setOpenedTab((prev) => ({ ...prev, [tab.name]: true }));
                    if (tab.tabs?.length > 0) {
                      const firstMenuIndex = tab.tabs.findIndex((item) => item.type !== "title");
                      setOpenedTab((prev) => ({
                        ...prev,
                        [tab.tabs[firstMenuIndex].name]: true,
                      }));
                      setSubMenus(tab.tabs);
                      setSubActiveTab(tab.tabs[firstMenuIndex].name);
                    } else {
                      setSubActiveTab(null);
                      setSubMenus(null);
                      setSubActiveInlineTab(null);
                    }
                  });
                }}
              >
                <GetIcon icon={tab.icon}></GetIcon>
                {t(tab.title)}
              </PopIconMenuItem>
            );
          })}
        </TabHeader>
      )}
      {subMenus?.length > 0 && (
        <TabHeader className={`menu ${className} ${popupMenu}`}>
          {subMenus.map((tab, index) => {
            return tab.type === "title" ? (
              <Title key={`${tab.name}-title`}>
                {tab.icon && <GetIcon icon={tab.icon}></GetIcon>}
                {t(tab.title)}
              </Title>
            ) : (
              <React.Fragment key={`${tab.name}-${index}`}>
                <PopMenuItem
                  key={`${tab.name}-${index}`} // Updated key
                  theme={themeColors}
                  className={`${tab.tabs?.length > 0 ? "submenu" : ""} ${subActiveTab === tab.name && "active"} ${popupMenu}`}
                  onClick={() => {
                    startTransition(() => {
                      setSubActiveTab(tab.name);
                      setOpenedTab((prev) => ({ ...prev, [tab.name]: true }));
                      if (tab.tabs?.length > 0) {
                        setSubActiveInlineTab(tab.tabs[0].name);
                      } else {
                        setSubActiveInlineTab(null);
                      }
                    });
                  }}
                >
                  {tab.icon && <GetIcon icon={tab.icon}></GetIcon>}
                  {t(tab.title)} {tab?.length}
                </PopMenuItem>
                {tab.tabs?.length > 0 && tab.name === subActiveTab && (
                  <InlineMenu>
                    {tab.tabs?.map((subTab, index) => (
                      <InlineMenuItem
                        key={`${subTab.name}-${index}`} // Updated key
                        theme={themeColors}
                        className={`${subActiveInlineTab === subTab.name && "active"} ${popupMenu}`}
                        onClick={() => {
                          startTransition(() => {
                            setSubActiveInlineTab(subTab.name);
                            setOpenedTab((prev) => ({
                              ...prev,
                              [subTab.name]: true,
                            }));
                          });
                        }}
                      >
                        {subTab.icon && <GetIcon icon={subTab.icon}></GetIcon>}
                        <span>{t(subTab.title)}</span>
                      </InlineMenuItem>
                    ))}
                  </InlineMenu>
                )}
              </React.Fragment>
            );
          })}
        </TabHeader>
      )}
      <TabContents className={`${popupMenu} ${subMenus ? "sub-menu" : "menu"}`}>
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={`${tab.name}-tab-content-${index}`}>
              <Tab className={`${className} ${popupMenu} ${tab.css ?? ""}`} theme={themeColors} active={subActiveTab === null && activeTab === tab.name}>
                {(openedTab[tab.name] === true || index === 0) && renderPage(tab, editData, setMessage, setLoaderBox, openData, parents)}
              </Tab>
              {tab.tabs?.map((subTab, index1) => (
                <React.Fragment key={`${subTab.name}-${index1}-tab-content`}>
                  <Tab className={`${className} ${popupMenu} ${subTab.css ?? ""}`} theme={themeColors} key={`${subTab.name}-sub-tab-content`} active={subActiveInlineTab === null && subActiveTab === subTab.name}>
                    {(openedTab[subTab.name] === true || (openedTab[tab.name] === true && index1 === 0)) && renderPage(subTab, editData, setMessage, setLoaderBox, openData, parents)}
                  </Tab>
                  {subTab.tabs?.map((subInlineTab, index2) => (
                    <React.Fragment key={`${subInlineTab.name}-${index2}-sub-sub-tab-content`}>
                      <Tab className={`${className} ${popupMenu} ${subInlineTab.css ?? ""}`} theme={themeColors} key={`${subInlineTab.name}-${index2}-sub-sub-tab-content-data`} active={subActiveInlineTab === subInlineTab.name}>
                        {(openedTab[subInlineTab.name] === true || (openedTab[subTab.name] === true && index2 === 0)) && renderPage(subInlineTab, editData, setMessage, setLoaderBox, openData, parents)}
                      </Tab>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
      </TabContents>
    </TabContainer>
  );
};

export default Tabs;
