import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Td,
  Tr,
  AddButton,
  ButtonPanel,
  Filter,
  Filters,
  ToggleContainer,
  ToggleInput,
  ToggleSlider,
  FilterBox,
  More,
  Actions,
  Title,
  DataItem,
  ToolTipContainer,
  Head,
  TrBody,
  TableView,
  TrView,
  ThView,
  TdView,
  TableContaner,
  ProfileImage,
  ListContainer,
  PageNumber,
  ListContainerData,
  ListContainerBox,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RowContainer } from "../../styles/containers/styles";
import { AddIcon, GetIcon, UploadIcon } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { deleteData, getData, postData, putData } from "../../../backend/api";

// import CrudForm from "./create";
import { addPageObject } from "../../../store/actions/pages";
import FormInput from "../input";
import Manage from "./manage";
import Loader from "../loader";
import Search from "../search";
import SubPage from "./subPage";
import DateRangeSelector from "../daterange";
import * as xlsx from "xlsx";
import { ToolTip } from "../../styles/list/styles";
import { dateFormat, dateTimeFormat } from "../functions/date";
import { convertMinutesToHHMM, findChangedValues, getValue } from "./functions";
import Popup from "./popup";
import Print from "./print/print";
import Highlight from "./highlight";
import Editable from "./editable";
import Details from "./details";
import PopupView from "../popupview";
import { TabContainer } from "./popup/styles";
import ImagePopup from "./image";
import moment from "moment";
import FileItem from "./file";
import { IconButton } from "../elements";
import { PageHeader } from "../input/heading";
import Pagination from "./pagination";
import NoDataFound from "./nodata";
import BulkUplaodForm from "./bulkupload";
import CrudForm from "./create";
import { noimage } from "../../../images";
import { ImageItem } from "./imagegallery/styles";
import ImageGallery from "./imagegallery";
const SetTd = React.memo((props) => {
  if (props.viewMode === "table") {
    return <TdView {...props}></TdView>;
  } else {
    return <Td {...props}></Td>;
  }
});
const SetTr = React.memo((props) => {
  if (props.viewMode === "table") {
    return <TrView {...props}></TrView>;
  } else {
    return <Tr {...props}></Tr>;
  }
});

// const CrudForm = React.lazy(() => import("./create"));
const ListTable = React.memo(
  ({
    name = "",
    rowLimit = 10,
    isSingle = false,
    icon,
    addLabel = null,
    popupMode = "medium",
    showInfo = true,
    showInfoType = "edit",
    customProfileSource = false,
    orientation = "portrait",
    profileImage,
    fileSource = "",
    displayColumn = "single",
    printPrivilege = false,
    formMode = "single",
    parentReference = "_id",
    referenceId = 0,
    actions = [],
    api,
    formView = "normal",
    setMessage,
    attributes = [],
    exportPrivilege = false,
    addPrivilege = false,
    delPrivilege = false,
    updatePrivilege = false,
    clonePrivilege = false,
    shortName = "Item",
    itemTitle = { type: "text", name: "title" },
    highlight = null,
    datefilter = false,
    preFilter = {},
    viewMode = "list",
    popupMenu = "horizontal",
    bulkUplaod = false,
    addtionalButtons = [],
    parents = {},
    itemDescription = { type: "datetime", name: "createdAt" },
    lastUpdateDate = null,
    fillType = "API",
    preData = [],
    onFilter = () => {},
    headerStyle = "",
  }) => {
    // console.log(parents, preFilter);
    const [preDataSet] = useState(preData);
    const [fillingType] = useState(fillType);
    const userData = useSelector((state) => state.pages);
    const pagesLoading = useSelector((state) => state.pagesLoading);
    const [dataLoaded, setDataLoaded] = useState(true);
    const [initialCheck, setInitialCheck] = useState(false);
    const [users, setUsers] = useState(null);
    const [filteredActions] = useState(
      actions.filter((item) => item.type === "button")
    );
    const [id, setId] = useState("");
    const [sortView, setSortView] = useState();

    useEffect(() => {
      setId(name + "-" + referenceId);
    }, [name, referenceId]); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
      const alldata = userData[`${api}-${id}`];
      const loading = pagesLoading[`${api}-${id}`] ?? false;
      setLoaderBox(loading);
      alldata && setUsers(alldata);

      if (!initialCheck) {
        if (!alldata && !loading) {
          console.log(alldata);
          setInitialCheck(true);
          setDataLoaded(false);
        }
      }
    }, [userData, api, pagesLoading, initialCheck, referenceId, id]);

    const [showSublist, setShowSubList] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [currentApi] = useState(`${api}`);
    const [subAttributes, setSubAttributes] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [perPage, setPerPage] = useState(rowLimit);
    const [pageNumber, setPageNumber] = useState(1);
    const [showPageCount, setShowPageCount] = useState(false);
    const [shoFilter, setShowFilter] = useState(false);
    const [count, setCount] = useState(0);
    const [editable, setEditable] = useState({});
    const [reset, setReset] = useState(0);
    const themeColors = useSelector((state) => state.themeColors);
    const selectedMenuItem = useSelector((state) => state.selectedMenu);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    /**
     * Function to set the showLoader state.
     * @param {boolean} status The status of the loader.
     */
    const setLoaderBox = (status) => {
      setShowLoader(status);
    };
    // const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 600px)").matches);

    // useEffect(() => {
    //   const updateIsMobile = () => {
    //     setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    //   };

    //   // Listen for changes in screen width
    //   const mediaQuery = window.matchMedia("(max-width: 600px)");
    //   mediaQuery.addEventListener("change", updateIsMobile);

    //   // Initial check
    //   updateIsMobile();

    //   // Cleanup
    //   return () => {
    //     mediaQuery.removeEventListener("change", updateIsMobile);
    //   };
    // }, []);
    // processing attributes

    function formatSize(sizeInBytes) {
      if (sizeInBytes === null || sizeInBytes === undefined) {
        return "0.0MB";
      }

      const sizeInMB = sizeInBytes / (1024 * 1024);
      const sizeInGB = sizeInBytes / (1024 * 1024 * 1024);

      if (sizeInGB >= 1) {
        return `${sizeInGB.toFixed(2)}GB`;
      } else {
        return `${sizeInMB.toFixed(2)}MB`;
      }
    }

    const [initialized, setInitialized] = useState(false);
    const [prevCrud, setPrevCrud] = useState("");
    const [formInput, setFormInput] = useState([]);
    const [errroInput, setErrorInput] = useState([]);
    const [addValues, setAddValues] = useState({});
    const [updateId, setUpdateId] = useState("");
    const [updateValues, setUpdateValues] = useState({});
    const [udpateView, setUpdateView] = useState(() => {});
    const [hasFilter, setHasFilter] = useState(false);
    const [filterView, setFilterView] = useState(
      referenceId !== 0
        ? { [parentReference]: referenceId, ...preFilter, ...parents }
        : { ...preFilter, ...parents }
    );
    useEffect(() => {
      console.log("filterView:", filterView);
    }, [filterView]);
    useEffect(() => {
      if (attributes.length > 0) {
        const addValuesTemp = {
          addValues: {},
          updateValues: {},
          viewValues: {},
          errorValues: {},
          filterValues: {},
          sortView: {},
        };
        // let tempFilter = false;
        let date = new Date();
        attributes.forEach((item) => {
          if (item.type === "checkbox") {
            let bool = JSON.parse(
              item.default === "false" || item.default === "true"
                ? item.default
                : "false"
            );
            if (item.add) {
              addValuesTemp.addValues[item.name] = bool;
            }
            addValuesTemp.updateValues[item.name] = bool;
          } else if (
            item.type === "datetime" ||
            item.type === "date" ||
            item.type === "time"
          ) {
            addValuesTemp.addValues[item.name] = date.toISOString();
            if (item.add) {
              addValuesTemp.addValues[item.name] =
                item.default === "empty"
                  ? ""
                  : moment(item.default).isValid()
                  ? moment(item.default).toISOString()
                  : date.toISOString();
              // addValuesTemp.updateValues[item.name] = date.toISOString();
            }
            if (item.type === "date" && (item.filter ?? false) === true) {
              // addValuesTemp.filterValues[item.name] = date.toISOString();
              // tempFilter = true;
            }
          } else if (item.type === "image" || item.type === "file") {
            if (item.add) {
              addValuesTemp.addValues[item.name] = [];
            }
            if (item.update) {
              addValuesTemp.updateValues[item.name] = [];
            }
          } else if (item.type === "multiSelect") {
            if (item.add) {
              addValuesTemp.addValues[item.name] = Array.isArray(item.default)
                ? item.default
                : [];
            }
            if (item.update) {
              addValuesTemp.updateValues[item.name] = [];
            }
          } else {
            if (item.add) {
              addValuesTemp.addValues[item.name] = item.default;
            }
            addValuesTemp.updateValues[item.name] = item.default;
            if (item.type === "select") {
              addValuesTemp.filterValues[item.name] = "";
              // tempFilter = true;
            }
          }
          if (item.sort ?? false) {
            addValuesTemp.sortView[item.name] = addValuesTemp.defaultSort ?? "";
          }
          addValuesTemp.errorValues[item.name] = "";
          addValuesTemp.filterValues["searchkey"] = "";
        });
        if (referenceId !== 0) {
          addValuesTemp.filterValues[parentReference] = referenceId;
        }
        setSortView(addValuesTemp.sortView);
        setFormInput(attributes);
        setAddValues(addValuesTemp.addValues);
        setErrorInput(addValuesTemp.errorValues);
        setUpdateValues(addValuesTemp.updateValues);
        setFilterView((prevFilterView) => {
          return { ...addValuesTemp.filterValues, ...prevFilterView };
        });
        // setFilter(tempFilter);
        setInitialized(true);
      }
    }, [
      attributes,
      dispatch,
      setPrevCrud,
      prevCrud,
      setFormInput,
      setAddValues,
      setUpdateValues,
      setFilterView,
      parentReference,
      referenceId,
      setSortView,
    ]);

    // end processing attributes
    useEffect(() => {
      //setLoaderBox(users.isLoading);
      if (currentIndex === 0 && users?.count) {
        setCount(users.filterCount);
        // setTotalCount(users.totalCount);
      }
    }, [users, currentIndex]);

    useEffect(() => {
      if (initialized && !dataLoaded) {
        setDataLoaded(true);
        dispatch(
          addPageObject(
            currentApi,
            currentIndex,
            { ...filterView, sorting: sortView },
            perPage,
            id,
            preDataSet,
            fillingType
          )
        );
      }
    }, [
      initialized,
      currentApi,
      dataLoaded,
      currentIndex,
      dispatch,
      filterView,
      sortView,
      perPage,
      id,
      preDataSet,
      fillingType,
    ]);

    useEffect(() => {
      const newPageNumber = Math.ceil((currentIndex + 1) / perPage);
      if (newPageNumber !== pageNumber) {
        setPageNumber(newPageNumber);
      }
    }, [count, currentIndex, perPage, pageNumber]);
    const refreshView = useCallback(
      (currentIndex) => {
        try {
          dispatch(
            addPageObject(
              currentApi,
              currentIndex,
              { ...filterView, sorting: sortView },
              perPage,
              id,
              preDataSet,
              fillingType
            )
          );
        } catch (error) {
          console.error("Error dispatching addPageObject:", error);
        }
      },
      [
        dispatch,
        currentApi,
        filterView,
        perPage,
        id,
        preDataSet,
        sortView,
        fillingType,
      ]
    );
    const refreshUpdate = (refresh = true, index = 0, update = {}) => {
      try {
        if (refresh) {
          dispatch(
            addPageObject(
              currentApi,
              currentIndex,
              { ...filterView, sorting: sortView },
              perPage,
              id,
              preDataSet,
              fillingType
            )
          );
        } else {
          const usetTemp = { ...users };
          const usetDataTemp = [...usetTemp.data.response];
          const current = usetDataTemp[index];
          usetDataTemp[index] = { ...current, ...update };
          usetTemp.data.response = usetDataTemp;
          setUsers(usetTemp);
        }
      } catch (err) {
        console.log("error", err);
      }
    };
    const [lastUpdatedDate, setLastUpdatedDate] = useState(lastUpdateDate);
    useEffect(() => {
      setLastUpdatedDate(lastUpdateDate);
    }, [lastUpdateDate]);

    useEffect(() => {
      if (lastUpdatedDate) {
        refreshView(0);
      }
    }, [lastUpdatedDate, refreshView]);
    const [isOpen, setIsOpen] = useState(false);
    const [detailView] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [printData, setPrintData] = useState([]);
    const [openData, setOpenData] = useState({});
    //crud functions
    const [showBulkUplad, setShowBulkUplad] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const isCreatingHandler = (value, callback) => {
      setIsCreating((prevIsCreating) => {
        if (prevIsCreating) {
          setUpdateView(() => callback);
          navigate({}, "", window.location.pathname);
          return false; // Set to false
        } else {
          window.location.hash = "add";
          return true; // Set to true
        }
      });
    };
    const [isEditing, setIsEditing] = useState(false);
    const isEditingHandler = (
      value,
      callback,
      titleValue,
      clone = false,
      view = false
    ) => {
      setLoaderBox(true);
      if (!isEditing) {
        if (!clone) {
          setUpdateView(() => callback);
          let updateValuesTemp = {};
          setUpdateId(value._id);
          formInput.forEach((item) => {
            let itemValue =
              item.collection?.length > 0 && item.showItem?.length > 0
                ? value[item.collection]?.[item.showItem]
                : value[item.name] ?? "";
            if (item.showSubItem) {
              itemValue =
                item.collection?.length > 0 && item.showItem?.length > 0
                  ? value[item.collection][item.showItem][item.showSubItem] ??
                    ""
                  : value[item.name];
            }
            if (item.update || item.view) {
              if (item.type === "checkbox") {
                let bool =
                  value[item.name]?.toString() === "true" ? true : false;
                updateValuesTemp[item.name] = bool;
              } else if (item.type === "number") {
                updateValuesTemp[item.name] = updateValuesTemp[item.name] =
                  parseFloat(value[item.name]);
              } else if (item.type === "select") {
                updateValuesTemp[item.name] =
                  typeof itemValue === "undefined"
                    ? ""
                    : typeof itemValue === "string" ||
                      typeof itemValue === "number" ||
                      typeof itemValue === "boolean"
                    ? itemValue
                    : value[item.name]?._id
                    ? value[item.name]._id
                    : "";
              } else if (item.type === "multiSelect") {
                try {
                  if (item.apiType === "API") {
                    updateValuesTemp[item.name] = value[item.name].map(
                      (obj) => obj._id
                    );
                  } else {
                    updateValuesTemp[item.name] = value[item.name].map(
                      (obj) => obj
                    );
                  }
                } catch (error) {
                  updateValuesTemp[item.name] = [];
                }
              } else if (item.type === "image") {
                updateValuesTemp["old_" + item.name] = value[item.name]
                  ? value[item.name]
                  : "";
                updateValuesTemp[item.name] = [];
              } else {
                updateValuesTemp[item.name] = itemValue ? itemValue : "";
              }
            }
          });
          updateValuesTemp["_id"] = value._id;
          updateValuesTemp["clone"] = clone;
          updateValuesTemp["_title"] = titleValue;
          setUpdateValues(updateValuesTemp);
          if (!view) {
            setIsEditing(true);
          }

          window.location.hash = "edit";
        } else {
          updateHandler({ id: value._id, _title: titleValue, clone: true });
        }
      } else {
        setUpdateId("");
        navigate({}, "", window.location.pathname);
        setIsEditing(false);
      }
      setLoaderBox(false);
    };
    const deleteHandler = async (item, id = "") => {
      await deleteData({ id }, currentApi, dispatch, navigate)
        .then((response) => {
          if (response.status === 200) {
            if (response.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response.customMessage,
                proceed: "Okay",
                icon: "success",
              });
            } else {
              setMessage({
                type: 1,
                content: `The '${
                  item.title ? item.title : shortName
                }' deleted successfully!`,
                proceed: "Okay",
                icon: "deleted",
              });
            }
            setCount((count) => count - 1);
            setIsCreating(false);
            refreshView(currentIndex);
            // udpateView(0);
          } else if (response.status === 404) {
            if (response.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response.customMessage,
                proceed: "Okay",
                icon: "error",
              });
            } else {
              setMessage({
                type: 1,
                content: "User not found!",
                proceed: "Okay",
                icon: "error",
              });
            }
          } else {
            if (response.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response.customMessage,
                proceed: "Okay",
              });
            } else {
              setMessage({
                type: 1,
                content: "Something went wrong!",
                proceed: "Okay",
                icon: "error",
              });
            }
          }
          setLoaderBox(false);
        })
        .catch((error) => {
          setMessage({
            type: 1,
            content: error.message + "Something went wrong!",
            proceed: "Okay",
            icon: "error",
          });
          setLoaderBox(false);
        });
    };
    const [action, setActions] = useState([]);
    const openAction = (item, data) => {
      // Actions Window
      setActions({ item, data });
      // setMessage({ type: 1, content: item.title + " / " + data._id, proceed: "Okay" });
    };
    const submitHandler = async (data) => {
      setLoaderBox(true);

      const saveData =
        referenceId === 0
          ? { ...data }
          : { ...data, [parentReference]: referenceId };

      // Filter and remove keys that have "*Array"
      const filteredData = Object.keys(saveData).reduce((acc, key) => {
        if (!key.includes("Array")) {
          acc[key] = saveData[key];
        }
        return acc;
      }, {});
      await postData({ ...parents, ...filteredData }, currentApi)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response?.data.customMessage,
                proceed: "Okay",
                icon: "success",
              });
            } else {
              setMessage({
                type: 1,
                content: `The '${shortName}' saved successfully!`,
                proceed: "Okay",
                icon: "success",
              });
            }
            setIsCreating(false);
            setCurrentIndex(0);
            refreshView(0);
            // udpateView(0);
          } else if (response.status === 404) {
            if (response?.data.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response?.data.customMessage,
                proceed: "Okay",
                icon: "error",
              });
            } else {
              setMessage({
                type: 1,
                content: "User not found!",
                proceed: "Okay",
                icon: "error",
              });
            }
          } else {
            console.log(response);
            if (response.customMessage?.length > 0) {
              setMessage({
                type: 1,
                content: response.customMessage,
                proceed: "Okay",
              });
            } else {
              setMessage({
                type: 1,
                content: "Something went wrong!",
                proceed: "Okay",
                icon: "error",
              });
            }
          }
          setLoaderBox(false);
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            type: 1,
            content: error.message + "Something went wrong!",
            proceed: "Okay",
            icon: "success",
          });
          setLoaderBox(false);
        });
    };
    useEffect(() => {
      if (users?.data?.response) {
        const data = users?.data?.response.find(
          (item) => item._id === updateId
        );
        if (data) {
          console.log("data", "Udpated");
          setOpenData((prev) => ({ ...prev, data: data }));
          setSubAttributes((prev) => ({ ...prev, data: data }));
        }
      }
    }, [users, isEditing, updateId, setOpenData, setSubAttributes]);
    const updateHandler = async (data, formInput, oldData) => {
      setLoaderBox(true);
      let status = false;
      // console.log({ data, formInput, oldData });
      const updatedItems = findChangedValues(oldData, data);
      const dataChanged = { ...updatedItems.changedObject, id: data._id };

      try {
        const response = await putData(dataChanged, `${currentApi}`);

        if (response.status === 200) {
          if (response?.data.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response?.data.customMessage,
              proceed: "Okay",
              icon: "success",
            });
            status = true;
          } else {
            setMessage({
              type: 1,
              content: `The '${data._title ?? shortName}' ${
                data.clone ? "cloned" : "updated"
              } successfully!`,
              proceed: "Okay",
              icon: "success",
            });
          }
          refreshView(currentIndex);
          setIsEditing(false);
        } else if (response.status === 404) {
          if (response?.data.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response?.data.customMessage,
              proceed: "Okay",
              icon: "error",
            });
          } else {
            setMessage({
              type: 1,
              content: "User not found!",
              proceed: "Okay",
              icon: "error",
            });
          }
        } else {
          console.log("Error", response);
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "Something went wrong!",
              proceed: "Okay",
              icon: "error",
            });
          }
        }
      } catch (error) {
        alert(error);
      } finally {
        setLoaderBox(false);
      }

      return status; // Return the status at the end of the function
    };

    const filterChange = useCallback(
      (option, name, type, parentFilter = false) => {
        if (!parentFilter) {
          const updateValue = {
            ...filterView,
            [name]:
              type === "select"
                ? option.id
                : type === "date"
                ? option?.toISOString()
                : "",
          };
          setFilterView(updateValue);
          setDataLoaded(false);
        } else {
          onFilter({
            [name]:
              type === "select"
                ? option.id
                : type === "date"
                ? option?.toISOString()
                : "",
          });
        }
        // updating the form values
      },
      [filterView, onFilter]
    );

    const dateRangeChange = (item) => {
      const startDate = new Date(item?.startDate);
      startDate.setHours(0, 0, 0, 0); // Set start date to 00:00

      const endDate = new Date(item?.endDate);
      endDate.setHours(23, 59, 59, 999); // Set end date to 23:59
      const udpateValue = {
        ...filterView,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      // updating the formm values
      setFilterView(udpateValue);
    };
    const closeManage = () => {
      setActions([]);
    };
    const generateDescription = useCallback(() => {
      const actions = [];
      const TempShortName = shortName.toLowerCase();

      if (addPrivilege) {
        actions.push("add");
      }
      if (updatePrivilege) {
        actions.push("edit");
      }
      if (delPrivilege) {
        actions.push("delete");
      }
      if (clonePrivilege) {
        actions.push("clone");
      }
      if (exportPrivilege) {
        actions.push("export");
      }

      if (actions.length === 0) {
        return `No actions are available for ${TempShortName}.`;
      }

      // Limit the number of displayed actions
      const maxActionsToShow = 3;
      const displayedActions = actions.slice(0, maxActionsToShow);

      // Preparing the final description
      let description = `You can ${displayedActions.join(", ")}`;

      // Add ellipsis for additional actions if truncated
      if (actions.length > maxActionsToShow) {
        description += `, or take additional actions on `;
      }

      // Determining the correct plural form
      let itemLabel = TempShortName;
      if (displayedActions.length > 1 && !TempShortName.endsWith("s")) {
        itemLabel += "s"; // Only add 's' if the last letter is not 's'
      }

      description += ` ${itemLabel}`;
      return description;
    }, [
      shortName,
      addPrivilege,
      updatePrivilege,
      delPrivilege,
      clonePrivilege,
      exportPrivilege,
    ]); // Dependencies

    const TableRowWithActions = React.memo(
      ({ attributes, data, slNo, selectRef }) => {
        const rowRef = useRef(null);
        selectRef.current[slNo] = rowRef;
        const titleValue =
          (itemTitle.collection?.length > 0
            ? data[itemTitle.collection]
              ? data[itemTitle.collection][itemTitle.name]
              : "NIl"
            : data[itemTitle.name]) ?? shortName;
        const signleRecord =
          viewMode === "list" ||
          viewMode === "subList" ||
          viewMode === "table" ||
          viewMode === "files"
            ? false
            : true;
        // data[attribute.name]?.title ? data[attribute.name]?.title : data[attribute.name]?.toString()

        const ActionDiv = (
          <React.Fragment key={`actions-${shortName}-${data._id}`}>
            {actions.map((item, index) => {
              return (
                item.element !== "button" && (
                  <ToggleContainer key={`${item.id}-${data._id}`}>
                    <ToggleInput
                      type="checkbox"
                      checked={data[item.id]}
                      onChange={async (event) => {
                        // item.callback(item, data);
                        setLoaderBox(true);
                        await postData(
                          { status: event.target.checked },
                          `${item.api}/${data._id}`,
                          dispatch,
                          navigate
                        )
                          .then((response) => {
                            if (response.status === 200) {
                              if (response.data?.message) {
                                setMessage({
                                  type: 1,
                                  content: response.data?.message,
                                  proceed: "Okay",
                                  icon: "success",
                                });
                              }
                              //
                              refreshView();
                              // setIsEditing(false);
                            } else if (response.status === 404) {
                              refreshView();
                              setMessage({
                                type: 1,
                                content: "Something Went Wrong!",
                                proceed: "Okay",
                                icon: "error",
                              });
                            } else {
                              refreshView();
                              setMessage({
                                type: 1,
                                content: "Something Went Wrong!",
                                proceed: "Okay",
                                icon: "error",
                              });
                            }
                            // setLoaderBox(false);
                          })
                          .catch((error) => {
                            alert(error);
                            // setLoaderBox(false);
                          });
                      }}
                    />
                    <ToggleSlider />
                  </ToggleContainer>
                )
              );
            })}
            {/* {!signleRecord && (
            <>
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  window.location.hash = "";
                  isEditingHandler(data, udpateView, titleValue, false, true);
                  setOpenData({ actions, attributes, data });
                  setSubAttributes({ actions, attributes, data });
                  setIsOpen(true);
                }}
              >
                <GetIcon icon={"open"}></GetIcon>
              </More>
            </>
          )} */}
            {updatePrivilege && (
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  isEditingHandler(data, udpateView, titleValue);
                }}
              >
                <GetIcon icon={"edit"} />
              </More>
            )}
            {delPrivilege && (
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  setMessage({
                    type: 2,
                    content: `Do you want to delete '${
                      getValue({ type: itemTitle.type ?? "text" }, titleValue)
                        ? getValue(
                            { type: itemTitle.type ?? "text" },
                            titleValue
                          )
                        : "Item"
                    }'?`,
                    proceed: "Delete",
                    onProceed: async () => {
                      await deleteHandler(data, data._id);
                      return false;
                    },
                    data: data,
                  });
                }}
              >
                <GetIcon icon={"delete"} />
              </More>
            )}
            {signleRecord && (
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  refreshView(currentIndex);
                }}
              >
                <GetIcon icon={"reload"}></GetIcon>
              </More>
            )}
            {filteredActions?.length > 0 && (
              <ToolTipContainer
                ref={rowRef}
                onClick={(event) => {
                  setCurrentAction(data._id);
                  event.stopPropagation();
                }}
              >
                <More
                  theme={themeColors}
                  className={currentAction === data._id ? `active` : ``}
                >
                  <GetIcon icon={"dots"}></GetIcon>
                </More>

                <ToolTip
                  className={
                    currentAction === data._id ? `actions` : `actions hide`
                  }
                >
                  <Actions>
                    {clonePrivilege && (
                      <Button
                        theme={themeColors}
                        key={`clone-${data._id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          setUpdateId(data._id);
                          setMessage({
                            type: 2,
                            content: `Do you want to clone '${
                              getValue(
                                { type: itemTitle.type ?? "text" },
                                titleValue
                              )
                                ? getValue(
                                    { type: itemTitle.type ?? "text" },
                                    titleValue
                                  )
                                : "Item"
                            }'?`,
                            proceed: "Clone",
                            onProceed: async () => {
                              await updateHandler({
                                cloneId: data._id,
                                _title: titleValue,
                                clone: true,
                              });
                            },
                            data: data,
                          });
                        }}
                        className="edit menu"
                      >
                        <GetIcon icon={"clone"} />
                        <span>Clone</span>
                      </Button>
                    )}
                    {filteredActions.map((item, index) => {
                      return (
                        item.type === "button" && (
                          <Button
                            theme={themeColors}
                            key={`custom-${item.id + "-" + index}-${data._id}`}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (item.type === "callback") {
                                item.callback(item, data, refreshUpdate, slNo);
                              } else if (item.type === "call") {
                                window.location.href = `tel:${data.mobileNumber}`;
                              } else if (
                                item.type === "subList" ||
                                item.type === "subItem"
                              ) {
                                setSubAttributes({ item, data });
                                setShowSubList(true);
                              } else {
                                openAction(item, data);
                              }
                            }}
                            className="edit menu"
                          >
                            <GetIcon icon={item.icon} />
                            <span>{item.title}</span>
                          </Button>
                        )
                      );
                    })}
                    {/* {delPrivilege && !signleRecord && (
                  <Button
                    theme={themeColors}
                    key={`delete-${data._id}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMessage({
                        type: 2,
                        content: `Do you want to delete '${getValue({ type: itemTitle.type ?? "text" }, titleValue) ? getValue({ type: itemTitle.type ?? "text" }, titleValue) : "Item"}'?`,
                        proceed: "Delete",
                        onProceed: async () => {
                          await deleteHandler(data, data._id);
                          return false;
                        },
                        data: data,
                      });
                    }}
                    className="delete menu"
                  >
                    <GetIcon icon={"delete"} />
                    <span>{"Delete"}</span>
                  </Button>
                )} */}
                  </Actions>
                </ToolTip>
              </ToolTipContainer>
            )}
          </React.Fragment>
        );
        let sticky = true;
        switch (viewMode) {
          case "table":
            return (
              <TrView
                onClick={() => {
                  if (!signleRecord) {
                    window.location.hash = "";
                    isEditingHandler(data, udpateView, titleValue, false, true);
                    setOpenData({ actions, attributes, data });
                    setSubAttributes({ actions, attributes, data });
                    setIsOpen(true);
                  }
                }}
                style={{ zIndex: users?.response?.length - slNo }}
                key={`${shortName}-${slNo}`}
              >
                {/* <TdView className={sticky} key={-1}>
                {slNo + 1 + currentIndex}
              </TdView> */}
                {attributes.map((attribute, index) => {
                  if (attribute.view && (attribute.tag ?? false)) {
                    try {
                      let itemValue =
                        attribute.collection?.length > 0 &&
                        attribute.showItem?.length > 0
                          ? data[attribute.collection][attribute.showItem]
                          : data[attribute.name];
                      if (attribute.showSubItem) {
                        itemValue =
                          attribute.collection?.length > 0 &&
                          attribute.showItem?.length > 0
                            ? data[attribute.collection][attribute.showItem][
                                attribute.showSubItem
                              ] ?? ""
                            : data[attribute.name];
                      }
                      let dynamicClass = "";
                      if (attribute.condition) {
                        if (
                          data[attribute.condition.item] ===
                          attribute.condition.if
                        ) {
                          dynamicClass = attribute.condition.then;
                        } else {
                          dynamicClass = attribute.condition.else;
                        }
                      }

                      const result = (
                        <TdView
                          className={sticky}
                          key={index}
                          onClick={() => {
                            if (attribute.editable === true) {
                              alert("yes");
                            } else {
                              // alert("no");
                            }
                          }}
                        >
                          {dynamicClass === "disabled" ? (
                            "--"
                          ) : (
                            <>
                              {/* {attribute.icon?.length > 0 && <GetIcon icon={attribute.icon} />} */}
                              <span>{getValue(attribute, itemValue)}</span>
                            </>
                          )}
                        </TdView>
                      );
                      sticky = false;
                      return result;
                    } catch (error) {
                      const result = (
                        <TdView className={sticky} key={index}>{`--`}</TdView>
                      );
                      sticky = false;
                      return result;
                    }
                  }

                  return null;
                })}
                <TdView
                  style={{ zIndex: users?.response?.length - slNo, border: 0 }}
                  key={`actions-${shortName}-${data._id}`}
                  className="actions"
                >
                  <div>
                    <div className="buttons">
                      {actions.map((item, index) => {
                        let status = true;
                        if (item.condition) {
                          if (
                            data[item.condition.item].toString() ===
                            item.condition.if.toString()
                          ) {
                            status = item.condition.then;
                          } else {
                            status = item.condition.else;
                          }
                        }
                        return (
                          item.type === "callback" &&
                          status && (
                            <More
                              theme={themeColors}
                              key={`custom-${item.id + "-" + index}-${
                                data._id
                              }`}
                              onClick={(event) => {
                                event.stopPropagation();
                                item.callback(item, data, refreshUpdate, slNo);
                              }}
                              className="edit menu callBack"
                            >
                              <GetIcon icon={item.icon} />
                              <span>{item.title}</span>
                            </More>
                          )
                        );
                      })}
                    </div>
                    {ActionDiv}
                  </div>
                </TdView>
              </TrView>
            );
          case "files":
            return (
              <FileItem
                fileSource={data[fileSource] || ""}
                title={titleValue}
                date={data["date"] ?? ""}
                onClick={() => {
                  if (!signleRecord) {
                    isEditingHandler(data, udpateView, titleValue, false, true);
                    setIsOpen(true);
                    window.location.hash = "";
                    setOpenData({ actions, attributes, data });
                    setSubAttributes({ actions, attributes, data });
                  }
                }}
                viewMode={viewMode}
                theme={themeColors}
                key={`row-${shortName}-${data._id ?? slNo}`}
                action={ActionDiv}
              ></FileItem>
            );
          case "gallery":
            return (
              <ImageItem
                onClick={(e) => {
                  setShowImage({
                    src: `https://event-hex-saas.s3.amazonaws.com/${data.compressed}`,
                  });
                }}
              >
                <img
                  alt={data.key}
                  src={`https://event-hex-saas.s3.amazonaws.com/${data.thumbnail}`}
                />
                <div>
                  <span>{formatSize(data.uploadedSize)}</span>
                  {delPrivilege && (
                    <IconButton
                      icon="delete"
                      theme={themeColors}
                      align="error small"
                      ClickEvent={(event) => {
                        event.stopPropagation();
                        setMessage({
                          type: 2,
                          content: `Do you want to delete '${
                            getValue(
                              { type: itemTitle.type ?? "text" },
                              titleValue
                            )
                              ? getValue(
                                  { type: itemTitle.type ?? "text" },
                                  titleValue
                                )
                              : "Item"
                          }'?`,
                          proceed: "Delete",
                          onProceed: async () => {
                            await deleteHandler(data, data._id);
                            return false;
                          },
                          data: data,
                        });
                      }}
                    ></IconButton>
                  )}
                </div>
              </ImageItem>
            );
          default:
            return (
              <SetTr
                onClick={() => {
                  if (!signleRecord) {
                    isEditingHandler(data, udpateView, titleValue, false, true);
                    setIsOpen(true);
                    window.location.hash = "";
                    setOpenData({ actions, attributes, data });
                    setSubAttributes({ actions, attributes, data });
                  }
                }}
                viewMode={viewMode}
                theme={themeColors}
                className={signleRecord ? "single" : ""}
                key={`row-${shortName}-${data._id ?? slNo}`}
              >
                {profileImage && (
                  <ProfileImage>
                    <img
                      src={
                        data[profileImage]
                          ? (customProfileSource
                              ? ""
                              : process.env.REACT_APP_CDN) + data[profileImage]
                          : noimage
                      }
                      onError={(e) => {
                        e.target.src = noimage; // Hide the image on error
                      }}
                      onClick={(e) => {
                        //image popup
                        e.stopPropagation();
                        setShowImage({
                          src: e.target.src.replace("/thumbnail", ""),
                        });
                      }}
                      alt="Profile"
                    ></img>
                  </ProfileImage>
                )}

                <ListContainerBox>
                  {!signleRecord && (
                    <TrBody className={signleRecord ? "nowrap" : "nowrap "}>
                      <SetTd key={`row-head-${slNo}`}>
                        <Head
                          onClick={() => {
                            isEditingHandler(
                              data,
                              udpateView,
                              titleValue,
                              false,
                              true
                            );
                            setIsOpen(true);
                            window.location.hash = "";
                            setOpenData({ actions, attributes, data });
                            setSubAttributes({ actions, attributes, data });
                          }}
                        >
                          {!profileImage && (
                            <GetIcon icon={icon ?? selectedMenuItem.icon} />
                          )}{" "}
                          <span>{` ${getValue(
                            { type: itemTitle.type ?? "text" },
                            titleValue
                          )}`}</span>
                          <Highlight
                            data={data}
                            highlight={highlight}
                          ></Highlight>
                        </Head>
                      </SetTd>
                      <Td
                        style={{ zIndex: users?.response?.length - slNo }}
                        key={`actions-${shortName}-${data._id}`}
                        className="actions"
                      >
                        {/* {attributes.map((attribute, index) => {
                        if (attribute.view && (attribute.tag ?? false) && attribute.type === "image") {
                          try {
                            let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem] : data[attribute.name];
                            let dynamicClass = "";
                            if (attribute.condition) {
                              if (data[attribute.condition.item] === attribute.condition.if) {
                                dynamicClass = attribute.condition.then;
                              } else {
                                dynamicClass = attribute.condition.else;
                              }
                            }
                            if (attribute.type === "image") {
                              return <div className={dynamicClass}>{getValue(attribute, itemValue)}</div>;
                            }
                          } catch (error) {
                            return null;
                          }
                        }

                        return null;
                      })} */}
                        <div> {ActionDiv}</div>
                      </Td>
                    </TrBody>
                  )}
                  {signleRecord ? (
                    <CrudForm
                      {...{
                        parentReference: parentReference,
                        referenceId: referenceId,
                        formMode: formMode,
                        api: api,
                        formType: "put",
                        updateId: updateId,
                        header: `${shortName}`,
                        formInput: formInput,
                        formErrors: errroInput,
                        formValues: data,
                        submitHandler: updateHandler,
                        isOpenHandler: isEditingHandler,
                        isOpen: isEditing,
                        profileImage: profileImage,
                        parentName: shortName,
                        parentIcon: icon,
                      }}
                      css="plain"
                    ></CrudForm>
                  ) : (
                    <TrBody>
                      {attributes.map((attribute, index) => {
                        if (attribute.view && (attribute.tag ?? false)) {
                          try {
                            let itemValue =
                              attribute.collection?.length > 0 &&
                              attribute.showItem?.length > 0
                                ? data[attribute.collection][attribute.showItem]
                                : data[attribute.name];
                            if (attribute.showSubItem) {
                              itemValue =
                                attribute.collection?.length > 0 &&
                                attribute.showItem?.length > 0
                                  ? data[attribute.collection][
                                      attribute.showItem
                                    ][attribute.showSubItem] ?? ""
                                  : data[attribute.name];
                            }
                            const itemColor =
                              attribute.collection?.length > 0 &&
                              attribute.color?.length > 0
                                ? data[attribute.collection][attribute.color]
                                : "initial";
                            let dynamicClass = "";
                            if (attribute.condition) {
                              if (
                                data[attribute.condition.item] ===
                                attribute.condition.if
                              ) {
                                dynamicClass = attribute.condition.then;
                              } else {
                                dynamicClass = attribute.condition.else;
                              }
                            }
                            if (attribute.type === "image") {
                              return "";
                            }
                            return (
                              <Td
                                className={"custom " + dynamicClass}
                                key={index}
                              >
                                {attribute.itemLabel !== undefined &&
                                attribute.itemLabel !== null ? (
                                  attribute.itemLabel === "" ? (
                                    ""
                                  ) : (
                                    <Title>{attribute.itemLabel}</Title>
                                  )
                                ) : (
                                  <Title>{attribute.label}</Title>
                                )}
                                <DataItem
                                  onClick={() => {
                                    if (attribute.editable === true) {
                                      const temp = { ...editable };
                                      temp[`${index}-${attribute.name}`] = temp[
                                        `${index}-${attribute.name}`
                                      ]
                                        ? !temp[`${index}-${attribute.name}`]
                                        : true;
                                      setEditable(temp);
                                    }
                                  }}
                                  style={{ color: itemColor }}
                                >
                                  {attribute.icon?.length > 0 && (
                                    <GetIcon icon={attribute.icon} />
                                  )}
                                  <span>{getValue(attribute, itemValue)}</span>
                                </DataItem>
                                {editable[`${index}-${attribute.name}`] ? (
                                  <Editable item={attribute} />
                                ) : (
                                  ""
                                )}
                              </Td>
                            );
                          } catch (error) {
                            let dynamicClass = "";
                            if (attribute.condition) {
                              if (
                                data[attribute.condition.item] ===
                                attribute.condition.if
                              ) {
                                dynamicClass = attribute.condition.then;
                              } else {
                                dynamicClass = attribute.condition.else;
                              }
                            }
                            return (
                              <Td
                                key={index}
                                className={"custom " + dynamicClass}
                              >
                                <Title>{attribute.label}</Title>
                                <DataItem>{`--`} </DataItem>
                              </Td>
                            );
                          }
                        }

                        return null;
                      })}
                    </TrBody>
                  )}
                  <TrBody className="actions">
                    {actions.map((item, index) => {
                      let status = true;
                      if (item.condition) {
                        if (
                          data[item.condition.item].toString() ===
                          item.condition.if.toString()
                        ) {
                          status = item.condition.then;
                        } else {
                          status = item.condition.else;
                        }
                      }
                      return (
                        item.type === "callback" &&
                        status && (
                          <More
                            theme={themeColors}
                            key={`custom-${item.id + "-" + index}-${data._id}`}
                            onClick={(event) => {
                              event.stopPropagation();
                              item.callback(item, data, refreshUpdate, slNo);
                            }}
                            className="edit menu callBack"
                          >
                            <GetIcon icon={item.icon} />
                            <span>{item.title}</span>
                          </More>
                        )
                      );
                    })}
                  </TrBody>
                </ListContainerBox>
              </SetTr>
            );
        }
      }
    );
    // const generateKey = (apiType, selectApi, filter, params) => {
    //   // Check if apiType is "API"
    //   if (apiType === "API") {
    //     // Ensure selectApi is defined, default to 'defaultType' if undefined
    //     const selectType = selectApi || "defaultType";

    //     // Ensure filter is an object, default to an empty object if undefined
    //     const validFilter = typeof filter === "object" && filter !== null ? filter : {};

    //     // Ensure params is an array, default to an empty array if undefined
    //     const validParams = Array.isArray(params) ? params : [];

    //     // Extract values from the filter object
    //     const filterEntries = Object.entries(validFilter).length > 0 ? Object.entries(validFilter).map(([key, value]) => `${key}:${value}`) : [];

    //     // Extract values from the params array
    //     const paramValues = validParams.length > 0 ? validParams.map((param) => `${param.name}:${param.value}`) : [];

    //     // Generate the key by concatenating selectType with filters and params
    //     const generatedKey = selectType + [...filterEntries, ...paramValues].join(",");

    //     return generatedKey;
    //   } else {
    //     return ""; // Return an empty string if apiType is not "API"
    //   }
    // };

    const closeModal = () => {
      setShowSubList(false);
      setIsOpen(false);
      window.location.hash = "";
      setIsPrint(false);
      setPrintData([]);
    };
    const [searchValue, setSearchValue] = useState("");
    // const [filter, setFilter] = useState(false);
    const searchTimeoutRef = useRef();
    const handleChange = (event) => {
      clearTimeout(searchTimeoutRef.current);
      setSearchValue(event.target.value);
      searchTimeoutRef.current = setTimeout(() => {
        setCurrentIndex(0);
        setFilterView({ ...filterView, searchkey: event.target.value });
        setDataLoaded(false);
      }, 400);
    };
    const selectRef = useRef([]);
    const [currentAction, setCurrentAction] = useState("");
    useEffect(() => {
      const handleClickOutside = (event) => {
        const clickedInsideRefs = selectRef.current.filter(
          (ref) => ref.current && ref.current.contains(event.target)
        );

        if (clickedInsideRefs.length === 0) {
          // alert("yes")
          setCurrentAction("");
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    //export to excel
    const toExcel = async (currentIndex) => {
      try {
        await exportToExcel();
      } catch (error) {
        alert(error);
      }
    };
    const printPage = async (currentIndex) => {
      try {
        setLoaderBox(true);
        await getData(
          { ...filterView, skip: 0, limit: 0 },
          api,
          dispatch,
          navigate
        )
          .then((response) => {
            setPrintData(response.data);
            setLoaderBox(false);
            setIsPrint(true);
          })
          .catch((error) => {
            setLoaderBox(false);
          });
      } catch (error) {
        alert(error);
      }
    };
    const exportToExcel = async () => {
      setLoaderBox(true);
      await getData({ ...filterView, skip: 0, limit: 0 }, api)
        .then((response) => {
          const jsonData = response.data.response;
          if (jsonData) {
            const excelData = [];
            jsonData.forEach((data) => {
              const excelRow = {};
              attributes.forEach((attribute) => {
                const name = attribute.label;
                if (attribute.export ?? true) {
                  try {
                    let itemValue =
                      attribute.collection?.length > 0 &&
                      attribute.showItem?.length > 0
                        ? data[attribute.collection][attribute.showItem] ?? ""
                        : data[attribute.name];
                    if (attribute.showSubItem) {
                      itemValue =
                        attribute.collection?.length > 0 &&
                        attribute.showItem?.length > 0
                          ? data[attribute.collection][attribute.showItem][
                              attribute.showSubItem
                            ] ?? ""
                          : data[attribute.name];
                    }
                    switch (attribute.type) {
                      case "text":
                      case "number":
                      case "password":
                      case "email":
                        return (excelRow[name] = itemValue);
                      case "minute":
                        return (excelRow[name] = convertMinutesToHHMM(
                          parseFloat(itemValue)
                        ));
                      case "datetime":
                        return (excelRow[name] = dateTimeFormat(itemValue));
                      case "date":
                        return (excelRow[name] = dateFormat(itemValue));
                      case "checkbox":
                        console.log({ itemValue });
                        return (excelRow[name] = itemValue.toString());
                      case "select":
                        if (attribute.apiType === "JSON") {
                          const fil = attribute.selectApi
                            .filter(
                              (item) =>
                                item.id.toString() ===
                                data[attribute.name]?.toString()
                            )
                            .map((filteredItem, index) => filteredItem.value);
                          return (excelRow[name] = fil?.[0]);
                        } else if (attribute.apiType === "CSV") {
                          return (excelRow[name] = itemValue);
                        } else {
                          return (excelRow[name] = itemValue ?? "Nil");
                        }
                      case "multiSelect":
                        if (attribute.apiType === "API") {
                          return (excelRow[name] = itemValue
                            .map((item) => item[attribute.showItem].toString())
                            .join(", "));
                        } else {
                          return (excelRow[name] = itemValue
                            .map((item) =>
                              attribute.selectApi
                                .find((label) => label.id === item)
                                .value.toString()
                            )
                            .join(", "));
                        }
                      default:
                        switch (typeof itemValue) {
                          case "undefined":
                            return (excelRow[name] = "Not Found");
                          case "object":
                            return (excelRow[name] = itemValue);
                          case "boolean":
                            return (excelRow[name] = itemValue.toString());
                          case "string":
                          case "number":
                          default:
                            if (
                              attribute.type === "select" &&
                              attribute.apiType === "JSON"
                            ) {
                              return attribute.selectApi
                                .filter(
                                  (item) =>
                                    item.id.toString() === itemValue?.toString()
                                )
                                .map(
                                  (filteredItem) =>
                                    (excelRow[name] = filteredItem.value)
                                );
                            } else {
                              return (excelRow[name] = itemValue
                                ?.toString()
                                .substring(0, 200));
                            }
                        }
                    }
                  } catch (ee) {
                    excelRow[name] = "--";
                  }
                }
              });
              excelData.push(excelRow);
            });
            const worksheet = xlsx.utils.json_to_sheet(excelData);

            // Create workbook
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, shortName);
            // Convert workbook to Excel binary and download the file
            xlsx.writeFile(workbook, shortName + "-data.xlsx");
            setLoaderBox(false);
          }
        })
        .catch((error) => {
          setLoaderBox(false);
        });
    };
    useEffect(() => {
      return () => {
        clearTimeout(searchTimeoutRef.current);
      };
    }, []);
    let headerSticky = true;
    const pageCount = Math.ceil(count / perPage);
    useEffect(() => {
      if (datefilter) {
        setHasFilter(true);
      }
      formInput.map((item, index) => {
        switch (item.type) {
          case "select":
            if ((item.filter ?? true) === true) setHasFilter(true);
            return true;
          case "date":
            if ((item.filter ?? false) === true) setHasFilter(true);
            return true;
          default:
            return true;
        }
      });
    }, [formInput, setHasFilter, datefilter]);

    const TableRows = React.memo(
      ({ users, selectRef, attributes, shortName }) => {
        return users?.response?.length > 0
          ? users.response.map((item, index) => (
              <TableRowWithActions
                selectRef={selectRef}
                key={`${shortName}-${index}`}
                slNo={index}
                attributes={attributes}
                data={item}
              />
            ))
          : null;
      },
      (prevProps, nextProps) => {
        // Optional: Custom comparison function for props
        return (
          prevProps.users === nextProps.users &&
          prevProps.attributes === nextProps.attributes &&
          prevProps.shortName === nextProps.shortName
        );
      }
    );

    // Usage in your component
    const tablerender = (
      <TableRows
        users={users}
        selectRef={selectRef}
        attributes={attributes}
        shortName={shortName}
      />
    );
    //end crud functions
    return attributes.length === 0 ? (
      <p>
        No attributes found for {shortName}. Please ensure that the data is
        correctly loaded.
      </p>
    ) : isSingle ? (
      users?.response?.length > 0 && (
        <RowContainer theme={themeColors} className={"data-layout " + viewMode}>
          <Popup
            isSingle={isSingle}
            parentName={shortName}
            parentIcon={icon}
            popupMode={popupMode}
            showInfo={showInfo}
            popupMenu={popupMenu}
            selectedMenuItem={selectedMenuItem}
            formMode={formMode}
            closeModal={closeModal}
            themeColors={themeColors}
            isEditingHandler={isEditingHandler}
            updateValue={udpateView}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            itemTitle={itemTitle}
            openData={{ actions, attributes, data: users?.response[0] }}
            updatePrivilege={updatePrivilege}
          ></Popup>
        </RowContainer>
      )
    ) : viewMode === "list" ||
      viewMode === "subList" ||
      viewMode === "table" ||
      viewMode === "files" ||
      viewMode === "gallery" ? (
      <RowContainer theme={themeColors} className={"data-layout " + viewMode}>
        <PageHeader
          dynamicClass={headerStyle}
          title={shortName}
          line={false}
          description={count > 0 ? generateDescription() : ""}
        ></PageHeader>
        {count === 0 ? (
          <NoDataFound
            setShowBulkUplad={setShowBulkUplad}
            bulkUplaod={bulkUplaod}
            shortName={shortName}
            icon={icon}
            addPrivilege={addPrivilege}
            addLabel={addLabel}
            isCreatingHandler={isCreatingHandler}
            refreshView={refreshView}
            className="white-list"
          ></NoDataFound>
        ) : (
          <React.Fragment>
            <ButtonPanel className={viewMode} theme={themeColors}>
              <FilterBox>
                {hasFilter && (
                  <Filter
                    className={shoFilter ? "active" : ""}
                    theme={themeColors}
                    onClick={() => {
                      setShowFilter(!shoFilter);
                    }}
                  >
                    <GetIcon icon={"filter"} />
                  </Filter>
                )}
                <Filter
                  theme={themeColors}
                  onClick={() => {
                    refreshView(currentIndex);
                  }}
                >
                  <GetIcon icon={"reload"} />
                </Filter>
                {exportPrivilege && (
                  <Filter
                    theme={themeColors}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMessage({
                        type: 2,
                        content: "Do you want export this page to excel?",
                        proceed: "Export Now",
                        onProceed: async () => {
                          await toExcel();
                          return true;
                        },
                        data: currentIndex,
                      });
                    }}
                  >
                    <GetIcon icon={"excel"} />
                  </Filter>
                )}
                {printPrivilege && (
                  <Filter
                    theme={themeColors}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMessage({
                        type: 2,
                        content: "Do you want print?",
                        proceed: "Print Now",
                        onProceed: async () => {
                          await printPage();
                          return false;
                        },
                        data: currentIndex,
                      });
                    }}
                  >
                    <GetIcon icon={"print"} />
                  </Filter>
                )}

                <Search
                  title={"Search"}
                  theme={themeColors}
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleChange}
                ></Search>
              </FilterBox>
              <FilterBox>
                {addtionalButtons.map((btn) => (
                  <AddButton
                    theme={themeColors}
                    onClick={() => btn.onClick(referenceId)}
                  >
                    <GetIcon icon={btn.icon} />
                    <span>{btn.label}</span>
                  </AddButton>
                ))}
                {(addPrivilege ? addPrivilege : false) && (
                  <AddButton
                    theme={themeColors}
                    onClick={() => isCreatingHandler(true, refreshView)}
                  >
                    {addLabel?.icon ? (
                      <GetIcon icon={addLabel.icon}></GetIcon>
                    ) : (
                      <AddIcon></AddIcon>
                    )}
                    <span>{addLabel?.label ?? shortName}</span>
                  </AddButton>
                )}
                {(bulkUplaod ? bulkUplaod : false) && (
                  <AddButton onClick={() => setShowBulkUplad((prev) => !prev)}>
                    <UploadIcon></UploadIcon>
                    <span>Bulk Upload {shortName}</span>
                  </AddButton>
                )}
              </FilterBox>
            </ButtonPanel>
            <Filters className={`${shoFilter ? "show-filter" : ""}`}>
              {datefilter && (
                <DateRangeSelector
                  onChange={dateRangeChange}
                  themeColors={themeColors}
                ></DateRangeSelector>
              )}
              {formInput.map((item, index) => {
                let updateValue = {};
                if (
                  (item.type === "select" || item.type === "multiSelect") &&
                  (item.filter ?? true) === true
                ) {
                  if (Array.isArray(item.updateOn)) {
                    updateValue = {};
                    item.updateOn?.forEach((itemName) => {
                      updateValue[itemName] = filterView[itemName];
                    });
                  } else {
                    updateValue = {
                      [item.updateOn]: filterView[item.updateOn],
                    };
                  }
                }
                switch (item.type) {
                  case "select":
                    return (
                      (item.filter ?? true) === true && (
                        <FormInput
                          updateValue={updateValue}
                          customClass={"filter"}
                          placeholder={item.placeHolder}
                          value={filterView[item.name]}
                          key={`input` + index + reset}
                          id={item.name}
                          {...item}
                          filter={{ ...preFilter, ...parents }}
                          onChange={(option, name, type) =>
                            filterChange(
                              option,
                              name,
                              type,
                              item.parentFilter ?? false
                            )
                          }
                          showLabel={false}
                          required={false}
                        />
                      )
                    );
                  case "date":
                    return (
                      (item.filter ?? false) === true && (
                        <FormInput
                          updateValue={updateValue}
                          customClass={""}
                          placeholder={item.placeHolder}
                          value={filterView[item.name]}
                          key={`input` + index + reset}
                          id={item.name}
                          {...item}
                          onChange={filterChange}
                          showLabel={false}
                          required={false}
                        />
                      )
                    );
                  default:
                    return null;
                }
              })}
              <IconButton
                align="margin-top"
                ClickEvent={() => {
                  setReset((re) => re + 1);
                  setFilterView(
                    referenceId !== 0
                      ? { [parentReference]: referenceId, ...preFilter }
                      : { ...preFilter }
                  );
                  setDataLoaded(false);
                }}
                type="secondary"
                value="Clear Filter"
                icon="delete"
              ></IconButton>
            </Filters>
            <ListContainer className={`${popupMenu} ${popupMode} `}>
              <ListContainerData>
                {viewMode === "table" ? (
                  <TableContaner>
                    <TableView theme={themeColors}>
                      <thead>
                        <tr>
                          {attributes.map((attribute) => {
                            if (attribute.view && (attribute.tag ?? false)) {
                              const item = (
                                <ThView
                                  className={headerSticky}
                                  key={shortName + attribute.name}
                                >
                                  <div>
                                    <span>{attribute.label}</span>{" "}
                                    {attribute.sort && (
                                      <IconButton
                                        ClickEvent={() => {
                                          setSortView((prev) => ({
                                            ...prev,
                                            [attribute.name]:
                                              prev[attribute.name] === "asc"
                                                ? "desc"
                                                : prev[attribute.name] ===
                                                  "desc"
                                                ? ""
                                                : "asc",
                                          }));
                                          setDataLoaded(false);
                                        }}
                                        icon="sort"
                                        align={`plain sort ${
                                          sortView?.[attribute.name] ?? ""
                                        }`}
                                      ></IconButton>
                                    )}
                                  </div>
                                </ThView>
                              );
                              headerSticky = false;
                              return item;
                            }
                            return null;
                          })}
                          <ThView key={"actions"}></ThView>
                        </tr>
                      </thead>
                      <tbody>{tablerender}</tbody>
                    </TableView>
                    {!users && !users?.response && (
                      <NoDataFound
                        bulkUplaod={bulkUplaod}
                        shortName={shortName}
                        icon={icon}
                        addPrivilege={addPrivilege}
                        isCreatingHandler={isCreatingHandler}
                        refreshView={refreshView}
                        className={`white-list ${displayColumn}`}
                      ></NoDataFound>
                    )}
                    {users?.response?.length === 0 && (
                      <NoDataFound
                        bulkUplaod={bulkUplaod}
                        shortName={shortName}
                        icon={icon}
                        addPrivilege={addPrivilege}
                        isCreatingHandler={isCreatingHandler}
                        refreshView={refreshView}
                        className={`white-list ${displayColumn}`}
                      ></NoDataFound>
                    )}
                  </TableContaner>
                ) : (
                  <>
                    <Table
                      className={`${viewMode} ${displayColumn} ${
                        count > 0 ? "" : "no-data"
                      }`}
                    >
                      {users?.response?.length > 0 &&
                        users.response.map((item, index) => (
                          <TableRowWithActions
                            key={`${shortName}-${index}`}
                            slNo={index}
                            attributes={attributes}
                            selectRef={selectRef}
                            data={item}
                          />
                        ))}
                      {!users && !users?.response && (
                        <NoDataFound
                          bulkUplaod={bulkUplaod}
                          shortName={shortName}
                          icon={icon}
                          addPrivilege={addPrivilege}
                          isCreatingHandler={isCreatingHandler}
                          refreshView={refreshView}
                          className={`white-list ${displayColumn}`}
                        ></NoDataFound>
                      )}
                      {users?.response?.length === 0 && (
                        <NoDataFound
                          bulkUplaod={bulkUplaod}
                          shortName={shortName}
                          icon={icon}
                          addPrivilege={addPrivilege}
                          isCreatingHandler={isCreatingHandler}
                          refreshView={refreshView}
                          className={`white-list ${displayColumn}`}
                        ></NoDataFound>
                      )}
                    </Table>
                  </>
                )}
              </ListContainerData>
            </ListContainer>
          </React.Fragment>
        )}
        {count > rowLimit && (
          <Pagination
            onClick={(index, PerPage) => {
              setPerPage(PerPage);
              setCurrentIndex(index);
              setDataLoaded(false);
            }}
            totalRows={count}
            perPage={rowLimit}
            currentIndex={currentIndex}
          ></Pagination>
        )}
        {!isCreating ? null : viewMode === "gallery" ? (
          <PopupView
            customClass={"side"}
            popupData={
              <ImageGallery
                viewMode="side"
                api={`${api}/upload`}
                openData={{
                  api: `${api}/upload`,
                  data: { _id: referenceId, ...parents },
                }}
              />
            }
            themeColors={themeColors}
            closeModal={() => setIsCreating(false)}
            itemTitle={{ name: "title", type: "text", collection: "" }}
            openData={{
              data: {
                key: "print_preparation",
                title: addLabel?.label ?? shortName,
              },
            }}
          />
        ) : (
          <CrudForm
            parentReference={parentReference}
            referenceId={referenceId}
            formMode={formMode}
            api={api}
            formType={"post"}
            header={`Add a ${shortName ? shortName : "Form"}`}
            formInput={formInput}
            formValues={addValues}
            formErrors={errroInput}
            submitHandler={submitHandler}
            isOpenHandler={isCreatingHandler}
            isOpen={isCreating}
          />
        )}

        {action.data && (
          <Manage
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            onClose={closeManage}
            {...action}
          ></Manage>
        )}

        {isOpen && updateValues && (
          <Popup
            showInfoType={showInfoType}
            editData={{
              parentReference: parentReference,
              referenceId: referenceId,
              formMode: formMode,
              api: api,
              formType: "put",
              updateId: updateId,
              header: `${shortName} details`,
              formInput: formInput,
              formErrors: errroInput,
              formValues: updateValues,
              submitHandler: updateHandler,
              isOpenHandler: isEditingHandler,
              isOpen: isEditing,
              profileImage: profileImage,
              parentName: shortName,
              parentIcon: icon,
            }}
            popupMode={popupMode}
            showInfo={showInfo}
            popupMenu={popupMenu}
            parents={parents}
            selectedMenuItem={selectedMenuItem}
            formMode={formMode}
            closeModal={closeModal}
            themeColors={themeColors}
            isEditingHandler={isEditingHandler}
            updateValue={udpateView}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            itemTitle={itemTitle}
            itemDescription={itemDescription}
            parentName={shortName}
            openData={openData}
            updatePrivilege={updatePrivilege}
            profileImage={profileImage}
          ></Popup>
        )}
        {isEditing && (
          <CrudForm
            parentReference={parentReference}
            referenceId={referenceId}
            formMode={formMode}
            api={api}
            formType={"put"}
            updateId={updateId}
            header={`${
              updateValues.clone === false
                ? `Update ${shortName}: `
                : `Clone ${shortName}: `
            }  <span style="font-weight:bold">'${updateValues._title}'</span>`}
            formInput={formInput}
            formErrors={errroInput}
            formValues={updateValues}
            submitHandler={updateHandler}
            isOpenHandler={isEditingHandler}
            isOpen={isEditing}
          ></CrudForm>
        )}
        {detailView && (
          <Details
            formMode={formMode}
            closeModal={closeModal}
            themeColors={themeColors}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            itemTitle={itemTitle}
            openData={openData}
          ></Details>
        )}
        {showSublist && subAttributes?.item?.attributes?.length > 0 && (
          <SubPage
            themeColors={themeColors}
            formMode={formMode}
            closeModal={closeModal}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            itemTitle={itemTitle}
            subAttributes={subAttributes}
          ></SubPage>
        )}
        {isPrint && (
          <PopupView
            customClass={"print"}
            popupData={
              <Print
                orientation={orientation}
                key={shortName}
                data={printData}
                themeColors={themeColors}
                formMode={formMode}
                closeModal={() => setIsPrint(false)}
                setMessage={setMessage}
                setLoaderBox={setLoaderBox}
                shortName={shortName}
                attributes={attributes}
              ></Print>
            }
            themeColors={themeColors}
            closeModal={() => setIsPrint(false)}
            itemTitle={{ name: "title", type: "text", collection: "" }}
            openData={{
              data: { key: "print_preparation", title: "Print " + shortName },
            }}
          ></PopupView>
        )}
        {showImage && (
          <ImagePopup
            onClose={() => setShowImage(null)}
            src={showImage.src}
          ></ImagePopup>
        )}
        {showLoader && <Loader list={"absolute"}></Loader>}
        {bulkUplaod && showBulkUplad && (
          <BulkUplaodForm
            bulkUpload={true}
            delPrivilege={delPrivilege}
            deleteHandler={deleteHandler}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            formMode={"single"}
            formView={formView}
            api={api}
            icon={icon}
            formType={"put"}
            updateId={updateId}
            header={"Bulk Upload " + shortName}
            formInput={formInput}
            formErrors={errroInput}
            formValues={updateValues}
            shortName={shortName}
            parents={{ [parentReference]: referenceId, ...parents }}
            currentApi={currentApi}
            submitHandler={() => {
              refreshView(currentIndex);
              setIsEditing(false);
              setShowBulkUplad(false);
            }}
            isOpenHandler={() => setShowBulkUplad(false)}
            isOpen={isEditing}
          ></BulkUplaodForm>
        )}
        {showPageCount && (
          <PopupView
            // Popup data is a JSX element which is binding to the Popup Data Area like HOC
            popupData={
              <>
                <TabContainer className="page">
                  <div className="head">Items Per Page</div>
                  {[10, 25, 50, 100, 250].map((num) => (
                    <PageNumber
                      theme={themeColors}
                      key={`per-${num}`}
                      className={"nomargin " + (perPage === num)}
                      onClick={() => {
                        setPerPage(num);
                        setDataLoaded(false);
                      }}
                    >
                      {num}
                    </PageNumber>
                  ))}
                </TabContainer>
                <TabContainer className="page">
                  <div className="head">
                    Pages: {pageCount} | Current Page: {pageNumber}
                  </div>
                  {Array.from(
                    { length: pageCount },
                    (_, index) => index + 1
                  ).map((num) => (
                    <PageNumber
                      key={`page-${num}`}
                      className={"nomargin " + (pageNumber === num)}
                      onClick={() => {
                        setCurrentIndex((num - 1) * perPage);
                        setDataLoaded(false);
                      }}
                    >
                      {num}
                    </PageNumber>
                  ))}
                </TabContainer>
              </>
            }
            themeColors={themeColors}
            closeModal={() => setShowPageCount(false)}
            itemTitle={{ name: "title", type: "text", collection: "" }}
            openData={{ data: { _id: "", title: "Pagination Setup!" } }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
            customClass={"small"}
          ></PopupView>
        )}
      </RowContainer>
    ) : (
      <RowContainer className={"data-layout "}>
        {users?.response?.length === 0 && (
          <ButtonPanel>
            <FilterBox></FilterBox>
            {(addPrivilege ? addPrivilege : false) &&
              users?.response?.length === 0 && (
                <AddButton
                  theme={themeColors}
                  onClick={() => isCreatingHandler(true, refreshView)}
                >
                  <AddIcon></AddIcon>
                  {shortName}
                </AddButton>
              )}
          </ButtonPanel>
        )}
        <Table
          className={users?.response?.length === 0 ? "norecord" : "record"}
        >
          {users?.response?.length > 0 && (
            <TableRowWithActions
              key={`${shortName}-${0}`}
              slNo={0}
              attributes={attributes}
              data={users?.response[0]}
              selectRef={selectRef}
            />
          )}
        </Table>
        {!users && !users?.response && (
          <NoDataFound
            bulkUplaod={bulkUplaod}
            shortName={shortName}
            icon={icon}
            addPrivilege={addPrivilege}
            isCreatingHandler={isCreatingHandler}
            refreshView={refreshView}
            className={`white-list ${displayColumn}`}
          ></NoDataFound>
        )}
        {users?.response?.length === 0 && (
          <NoDataFound
            bulkUplaod={bulkUplaod}
            shortName={shortName}
            icon={icon}
            addPrivilege={addPrivilege}
            isCreatingHandler={isCreatingHandler}
            refreshView={refreshView}
            className={`white-list ${displayColumn}`}
          ></NoDataFound>
        )}
        {isCreating && (
          <CrudForm
            parentReference={parentReference}
            referenceId={referenceId}
            api={api}
            formMode={formMode}
            formType={"post"}
            header={`Add a ${shortName ? shortName : "Form"}`}
            formInput={formInput}
            formValues={addValues}
            formErrors={errroInput}
            submitHandler={submitHandler}
            isOpenHandler={isCreatingHandler}
            isOpen={isCreating}
          ></CrudForm>
        )}
        {isEditing && (
          <CrudForm
            parentReference={parentReference}
            referenceId={referenceId}
            formMode={formMode}
            api={api}
            formType={"put"}
            updateId={updateId}
            header={`${
              updateValues.clone === false
                ? `Update ${shortName}: `
                : `Clone ${shortName}: `
            } <span style="font-weight:bold">'${updateValues._title}' </span>`}
            formInput={formInput}
            formErrors={errroInput}
            formValues={updateValues}
            submitHandler={updateHandler}
            isOpenHandler={isEditingHandler}
            isOpen={isEditing}
          ></CrudForm>
        )}
        {action.data && (
          <Manage
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            onClose={closeManage}
            {...action}
          ></Manage>
        )}
        {isOpen && (
          <Popup
            parentReference={parentReference}
            popupMenu={popupMenu}
            data={openData}
            actions={actions}
          ></Popup>
        )}

        {showLoader && <Loader list={"absolute"}></Loader>}
      </RowContainer>
    );
  }
);
export default ListTable;
